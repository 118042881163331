import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { scrypt } from "scrypt-js";
import getConfigInstance from "../../env/Config";
import "./Login.scss";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { useTranslation } from "react-i18next";

const cfg = getConfigInstance();
const urlApi = cfg.getValue("apiUrl");

const Login = () => {
  const [seed, setSeed] = React.useState<string | null>(null);
  const [userId, setUserId] = React.useState<number | null>(null);
  const nameRef = React.useRef<HTMLInputElement>(null);
  const passwordRef = React.useRef<HTMLInputElement>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const fetchSeed = async (name: string) => {
    try {
      setLoading(true);
      const response = await fetch(`${urlApi}/login/user/${name}`);
      if (response.ok) {
        const data = await response.json();
        setUserId(data.userId);
        setSeed(data.seed);
      } else {
        console.error("Failed to fetch seed");
      }
    } catch (error) {
      console.error("Error fetching seed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNameBlur = () => {
    const name = nameRef.current?.value;
    if (name) {
      fetchSeed(name);
    }
  };
  const renderWithLineBreaks = (text: string) => {
    return text.split(/[\r\n]+/).map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
const handleLogin = async () => {
    const name = nameRef.current?.value;
    const pwd = passwordRef.current?.value;
    if (name && pwd && seed) {
      setLoading(true);
      const password = new TextEncoder().encode(pwd.normalize('NFKC'));
      const salt = new TextEncoder().encode(seed.normalize('NFKC'));

      const N = 1024, r = 8, p = 1;
      const dkLen = 32;

      // Generate the hash
      const key = await scrypt(password, salt, N, r, p, dkLen);
      const hash = Array.from(new Uint8Array(key)).map(b => b.toString(16).padStart(2, '0')).join('');

      const urlNew = `${urlApi}/login/password?userId=${userId}&hash=${hash}`;
      window.location.replace(urlNew);
    }
  };

  if (!i18n.isInitialized) {
    return <Loader type="converging-spinner" themeColor="info" className="loader-login"/>;
  }

  return (
    <div className="login-container">
      <div className="login-image">
        <img
          src="images/airplane.jpeg"
          alt="Airport"
        />
        <div className="login-text">
          <h1 className="welcome-2-text">{renderWithLineBreaks(t("Login.login-text-1"))}</h1>
          <p className="welcome-2-subtext">
          {renderWithLineBreaks(t("Login.login-text-2"))}
          </p>
        </div>
      </div>
      <div className="login-form">
        <img
          src="images/logo.png"
          alt="Logo"
        />

        <h6 className="welcome-text">{t("Login.login-welcome-1")}<a href="http://cubusstar.com" target="_blank" className="welcome-link" rel="noreferrer">{t("Login.login-welcome-2")}</a></h6>
        <p className="welcome-subtext">{t("Login.login-welcome-3")}</p>
        <label>
        {t("Login.login-username")}
          <Input
            ref={nameRef}
            type="text"
            onBlur={handleNameBlur}
            onKeyDown={handleKeyPress}
            placeholder={t("Login.login-username-help-text")}
          />
        </label>
        <label>
          {t("Login.login-password")}
          <Input
            ref={passwordRef}
            type="password"
            onKeyDown={handleKeyPress}
            placeholder={t("Login.login-password-help-text")}
          />
          <a href="/forgot-password" className="forgot-password">
            {t("Login.login-forgot-password")}
          </a>
        </label>
        <Button onClick={handleLogin} disabled={loading}>
          {t("Login.login-button")}
        </Button>
        {loading && (
          <div className="overlay">
            <div className="loader-container">
            <Loader type="converging-spinner"  themeColor="info" />
          </div>
          </div>
        )}
      </div>
    </div>
  );

};

export default Login;
