import { Link, useSearchParams } from "react-router-dom";

const Error = () => {

    const [queryParameters] = useSearchParams();
    return (
        <div>
     
        <h1>CubusSTAR&trade; Login</h1>
            <h2>Error</h2>
            <p>Message: {queryParameters.get("message")}</p>
            <Link to="/">Back</Link>     
           </div>
       );
}

export default Error;