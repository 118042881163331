import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: localStorage.getItem('i18nextLng') || "pt",
    debug: true,
    backend: {
      loadPath:  `https://cubusstar.com/core/api/Language/{{lng}}`
    },
    ns: ['Common', 'Login'],
    defaultNS: 'Common',
    react: {
      useSuspense: false
    },
  })
  .catch((e) => {
    console.error('error i18n: ', e);
  });

export default i18n;
