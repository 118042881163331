import { Link } from "react-router-dom";

const Unauthorized = () => {
    return (
        <div>
     
     <h1>CubusSTAR&trade; Login</h1>
         <h2>Unauthorized</h2>
         <Link to="/">Back</Link>     
        </div>
       );
}

export default Unauthorized;