import React from 'react';
import './App.css';
import { Routes, Route, HashRouter  } from "react-router-dom";
import Name from './components/Name';
import Password from './components/Password';
import Error from './components/Error';
import Unauthorized from './components/Unauthorized';
import LoginPage from './components/LoginPage';
import getConfigInstance from './env/Config';
import Login from './components//Login/Login';
import './i18n';
import '@progress/kendo-theme-default/dist/all.css'

function App() {

  const cfg = getConfigInstance();
  return (
    <div className="App">
       
     <HashRouter >
        <Routes>
          {/* <Route path="/"               > */}
            <Route index element={<Login />} /> 
            <Route path="/name"         element={<Name />} />
            <Route path="/password"     element={<Password />} />
            <Route path="/unauthorized" element={<Unauthorized />} />     
            <Route path="/error"        element={<Error />} />        
          {/* </Route> */}
        </Routes>
      </HashRouter >
   

    </div>
  );
}

export default App;
