import * as React from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import getConfigInstance from '../env/Config';
import bcrypt from 'bcryptjs';
import { useEffect } from 'react';


//Salt must be in the form of: $Vers$log2(NumRounds)$saltvalue]
// $Vers is bcrypt_id
// $log2(NumRounds) is a parameter that tells it how many times to execute that internal hash function.
// Example: $2a$10$31c/rf9T9/vGFFnK6D4Yh.

const BCRYPT_ID = "$2a"           // $Vers is bcrypt_id
const BCRYPT_NUM_ROUNDS = "$12"   // $log2(NumRounds)
const BCRYPT_SEED_START = "$"



const cfg = getConfigInstance();
const apiUrl = cfg.getValue("apiUrl");

const Password = () => {

  console.log("Password apiUrl: " + apiUrl);
  const [queryParameters] = useSearchParams();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const guid:string  = queryParameters.get("GUID") as string;
  const userId:string  = queryParameters.get("userId") as string;
 
  const userSeed = BCRYPT_ID + BCRYPT_NUM_ROUNDS + BCRYPT_SEED_START + guid;

  console.log('userSeed:', userSeed);

  
  const [password, setPassword] = React.useState('');
  const [hashedPassword, setHashedPassword] = React.useState('');

  const handleHashPassword = async () => {
    const hash = await bcrypt.hash(password, userSeed);
    //const hash = await bcrypt.hash(password, suserId);
    setHashedPassword(hash);
    // Send the hashed password to the server
    console.log('Hashed Password:', hash);
  };


  const handleKeyPress = (event: { keyCode: number; which: number; }) => {
    // look for the `Enter` keyCode
    if (event.keyCode === 13 || event.which === 13) {
      handleHashPassword()
    }
  }


  useEffect(() => {
    if (hashedPassword){
      navigate()
    }
   
  }, [hashedPassword]); // Dependency on the path



  // will not be able to use window back
  const navigate = () => {

    const urlNew = apiUrl + "/login/password?userId=" + userId + "&hash=" + hashedPassword;
    console.log("navigate urlNew: " + urlNew);
    window.location.replace(urlNew)
  };

  const NavigateLocReplace = () => {
    return (
      <button onClick={handleHashPassword} type="submit">
        Continue
      </button>
    )
  }

  return (
    <div>
      <h1>CubusSTAR&trade; Login</h1>
      <h3>Enter Password</h3>
      <p>UserId: {queryParameters.get("userId")}</p>
      <p>GUID: {queryParameters.get("GUID")}</p>

      <label>Password: &nbsp;
        <input ref={inputRef} type="password" id="pass" name="password" required autoFocus onKeyDown={handleKeyPress} onChange={(e) => setPassword(e.target.value)} />
      </label>
      <NavigateLocReplace />
    </div>
  );
};

export default Password;
  