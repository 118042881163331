import * as React from 'react';

import { useRef } from 'react';

import { useNavigate } from "react-router-dom";
import getConfigInstance from '../env/Config';




const cfg = getConfigInstance();
const apiUrl = cfg.getValue("apiUrl");


const Name = () => {

  console.log("Name apiUrl: " + apiUrl);

  
  const inputRef = React.useRef<HTMLInputElement>(null);



  const handleKeyPress = (event: { keyCode: number; which: number; }) => {
    // look for the `Enter` keyCode
    if (event.keyCode === 13 || event.which === 13) {
      navigate()
    }
  }

  const navigate = () => {
    const urlNew = apiUrl + "/login/user?name=" + inputRef.current?.value;
    console.log("navigate urlNew: " + urlNew);
    window.location.replace(urlNew)
  }

  const NavigateLocReplace = () => {
    // will not be able to use window back

    return (
      <button onClick={navigate} type="submit">
        Continue
      </button>)
  }



  return (
    <div>

      <h1>CubusSTAR&trade; Login</h1>
      <h3>Enter Name</h3>

      <label>User name:&nbsp;
        <input ref={inputRef} name="username" type="text" autoFocus onKeyDown={handleKeyPress} />
      </label>

      <NavigateLocReplace />
      <div>
        <small>baseApiUrl: {apiUrl}</small>
      </div>
    </div>

  );
};

export default Name;
