/// https://betterprogramming.pub/creating-a-singleton-in-typescript-dc320904e058

/**
 * Type of whatever we want to store in the singleton
 * In this case, it is an object with a name attribute
 */

// export interface IConfigListProps {
//     config?: Array<IConfigType> | undefined;
// }

// export interface IConfigType {
//     key: string;
//     value: string;
// }


/**
 * The only instance of our Singleton
 */
let instance: ReturnType<typeof makeConfig<string>>;

/**
 * Singleton supplies accessors using Revealing Module 
 * pattern and we use generics, since we could reuse 
 * this across multiple singletons
 *
 * Note: Object.freeze() not required due to type narrowing!
 */
const makeConfig = <T>(initial?: T) => {
    console.log("makeConfig");
    /** Closure of the singleton's value to keep it private */
    let _config: T | undefined = initial;
    /** Only the accessors are returned */
    return {
        //getValue: (): T | undefined => _config,
        // setValue: (value: T) => _config = value,
        getValue: (key: string): string => {
            let jsonData = JSON.parse(_config as string);
            let value = jsonData[key];
            return value;
        }
    };
};
// fetct is async - want the result before we continue
const synchronousRequest = (url: string):string =>{
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, false);
    xhr.send(null);
    if (xhr.status === 200) {
        return xhr.responseText;
    } else {
        throw new Error('Request failed: ' + xhr.statusText);
    }
}

/**
 * Retrieves the only instance of the Singleton
 * and allows a once-only initialisation
 * (additional changes require the setValue accessor)
 */
const getConfigInstance = () => {
    console.log("getConfigInstance");
    if (!instance) {
        console.log("getConfigInstance fetch");
        try {
            const url = "config/config.json";
            const responseData = synchronousRequest(url);
            console.log('Response data:', responseData);
            instance = makeConfig<string>(responseData);
        } catch (error) {
            console.error('Error:', error);
        }

        return instance;
    }
    console.log("getConfigInstance no fetch");
    return instance;
};

export default getConfigInstance;

// Usage
//
// import getConfigInstance from "./env/Config";
// const cfg = getConfigInstance();
// const theNameObj = cfg.getValue("key");
//